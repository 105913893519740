<template>
    <v-card flat>
        <v-card-title>                                                  
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search Subject"
                single-line
                hide-details
                clearable
            ></v-text-field>
        </v-card-title>                    
        <v-data-table
            class="mt-10"
            :headers="headersSubjects"
            :items="subjects"
            :search="search"
            fixed-header
            height="40vh"
            :loading="loadingSubjects"            
        >
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn                    
                    outlined
                    color="primary"
                    small
                    @click="editViewSubjectAssignment(item)"
                >                    
                    <v-icon
                        left
                        medium 
                        outlined
                    >
                        mdi-account-multiple-plus
                    </v-icon>
                        View/Add Students 
                </v-btn>
            </template>
        </v-data-table>

         <v-dialog
            v-model="dialog"
            max-width="800px"
            persistent
        >
            <v-card>
                <v-card-title>
                    {{ editedSubject.title }}
                    <v-spacer></v-spacer>                   
                    <v-text-field
                        v-model="searchStudentAssigned"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        clearable
                    ></v-text-field>
                </v-card-title>
                
                <v-card-text>
                    <v-data-table
                        v-model="selected"
                        :headers="headersStudents"
                        :items="subjectStudents"
                        fixed-header                        
                        height="50vh"
                        :calculate-widths="true"                        
                        :disable-pagination="true"                        
                        :search="searchStudentAssigned"
                        :loading="loadingAssignedStudents"
                        :single-select="false"
                        :show-select="showSelect"
                        item-key="student_id"
                    >
                        <template v-slot:item="{ item, isSelected, select }">
                            <tr>
                                <td v-if="showSelect">
                                    <v-checkbox
                                        :value="isSelected"
                                        @change="select($event)"
                                    ></v-checkbox>
                                </td>
                                <td>{{ item.student_id }}</td>
                                <td>{{ item.last_name }}, {{ item.first_name }}</td>                                
                                <td>{{ item.class_id }}</td>
                                <td>
                                    <v-autocomplete
                                        v-model="item.employee_id"
                                        hide-details
                                        chips
                                        deletable-chips
                                        dense
                                        outlined
                                        :items="teachers"
                                        item-value="id"
                                        item-text="name"
                                        @change="updateTeacher(item)"
                                    >                                        
                                    </v-autocomplete>
                                </td>
                            </tr>
                        </template> 
                    </v-data-table>
                </v-card-text>

                <v-card-actions
                    class="px-6 pb-4"
                >                   
                    <v-card 
                        flat
                        v-if="updating"
                    >
                        <span 
                            style="font-size:0.7rem;"
                        >
                            {{ saveStatus }}
                        </span>
                        
                        <v-icon
                            small
                            color="green" 
                            v-if="saved"
                            class="mx-2"
                        >
                            mdi-check-all
                        </v-icon>                        
                        
                        <v-progress-circular
                            indeterminate
                            color="primary"
                            v-if="saving"
                            size="16"
                            width="3"
                            class="mx-2"
                        ></v-progress-circular>                        
                        
                        <v-icon
                            small
                            color="red"
                            v-if="saveError"
                            class="mx-2"
                        >
                            mdi-alert-circle
                        </v-icon>
                        
                    </v-card>

                    <v-spacer></v-spacer>

                    <v-btn
                        v-if="!showSelect"
                        @click="closeEdit"
                        color="primary"
                        text
                    >                        
                        Close                        
                    </v-btn>
                    <v-btn
                        v-if="showSelect" 
                        color="primary"
                        @click="cancelDeleteStudents"
                        outlined
                    >
                        <v-icon left>mdi-arrow-left</v-icon>
                        Back
                    </v-btn>
                    <v-btn
                        v-if="!showSelect"
                        color="primary"                                                              
                        @click="displaySelect"
                        outlined                    
                    >
                        Delete Students
                    </v-btn>                    
                    <v-btn
                        v-if="showSelect"
                        color="primary"                                           
                        @click="deleteStudents"                    
                    >
                        Delete Selected
                    </v-btn>                    
                    <v-btn
                        v-if="!showSelect"
                        color="primary"
                        dark                        
                        @click="addStudent"
                    
                    >
                        Add Student
                    </v-btn>
                    
                </v-card-actions>

                <v-overlay
                    absolute
                    :value="deleteOverlay"                    
                    color="grey lighten-5"
                    opacity="0.9"
                    class="pa-5"
                >      
                    <v-expand-transition>
                        <v-card
                            v-show="displayDeleteConfirm"                            
                            light
                            min-width="300px"
                        >
                            <v-card-title>
                                {{ deleteConfirmMessage }}
                            </v-card-title>

                            <v-card-actions class="px-4 pb-4">
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    outlined
                                    small
                                    @click="cancelDeleteConfirm"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    dark
                                    small
                                    @click="deleteConfirm"
                                >
                                    Delete
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-expand-transition>    

                    <v-progress-circular
                        v-show="progressDelete"                       
                        transition="fade-transition" 
                        indeterminate 
                        size="64"                            
                        color="blue"
                    ></v-progress-circular>                    
                </v-overlay>      
            </v-card>

            <v-dialog
                v-model="dialogStudent"
                max-width="900px"               
            >
                <v-card>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="6">
                                    <v-autocomplete
                                        v-model="studentSelected"
                                        :items="students"
                                        item-text="name"
                                        item-value="id"                                        
                                        label="Student"
                                        return-object                      
                                        hide-details
                                        autofocus                                        
                                    >
                                        <template v-slot:selection="{ item }">
                                            {{ item.name }}
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.name  + ' - ' + item.form_class}}
                                                </v-list-item-title>                  
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="6">
                                    <v-autocomplete
                                        v-model="teacherSelected"
                                        :items="teachers"
                                        item-text="name"
                                        item-value="id"
                                        label="Teacher"
                                        @change="setSubjectStudents"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>    
                    </v-card-text>
                    <v-overlay
                        absolute
                        :value="overlay"                    
                        color="grey lighten-5"
                        opacity="0.9"
                        class="pa-5"
                    >      
                        <v-progress-circular                            
                            transition="fade-transition" 
                            indeterminate 
                            size="64"                            
                            color="blue"
                        ></v-progress-circular>
                    
                    </v-overlay>                    
                </v-card>
            </v-dialog>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :centered="snackCentered"
        >
            <span >{{ snackText }}</span>
            <template v-slot:action="{ attrs }">
                <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar> 

    </v-card>  
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    props: {
        subjects: {
            type: Array,
            default: function () {
                return [];
            }
        },

        teachers: {
            type: Array,
            default: function () {
                return [];
            }
        }
    },

    computed: {
        deleteConfirmMessage () {
            if(this.selected.length == 1) return "Delete Student ?";
            if(this.selected.length == 0) return "Please Select Students to Delete";
            return `Delete ${this.selected.length} Students ?`;
        },        
    },

    data: () => ({
        search: '',        
        headersSubjects: [
            {text: 'Subject ID', align: 'start', sortable: 'false', value: 'id', width: '40'},
            {text: 'Subject', align: 'start', sortable: 'false', value: 'title', width: '160'},
            {text: 'Action', value: 'actions', sortable: 'false', width: '120'}
        ],
        headersStudents: [
            {text: 'ID', align: 'start', value: 'student_id', width: '50'},
            {text: 'Name', width: '200', value: 'name'},            
            {text: 'Class', value: 'class_id', width: '100'},            
            {text: 'Teacher',  value: 'teacher' },
        ],        
        snackbar: false,
        snackText: '',
        searchStudentAssigned: '',
        dialogStudent: false,
        overlay: false,
        deleteOverlay: false,
        loadingAssignedStudents: true,        
        next: false,             
        students: [],               
        subjectStudents: [],
        studentSelected: '',
        subjectSelected: null,
        teacherSelected: null,
        loading: true,       
        dialog: false,
        editedSubject: {},
        formClasses: [],
        saveStatus: '',
        saving: false,
        saved: false,
        saveError: false,       
        loadingSubjects: false,
        showSelect: false,
        selected: [],
        displayDeleteConfirm: false,
        progressDelete: false,
        snackColor: '',
        snackCentered: false,
        updating: false,
    }),    

    methods: {
        ...mapActions({
            postSubjectStudent: 'admin/postSubjectStudents',
            deleteSubjectStudent: 'admin/deleteSubjectStudent',
            getStudents: 'admin/getStudents',
            getSubjectStudents: 'admin/getSubjectStudents',
        }),

        ...mapMutations({
            setSubject: 'admin/setSubjectSelected',
            setStudent: 'admin/setStudentId',
            setEmployee: 'admin/setEmployeeId',
            setSelectedStudents: 'admin/setSelectedStudents',   
        }),

        async setSubjectStudents () {
            if(this.studentSelected && this.teacherSelected){
                this.overlay = true;
                this.setStudent(this.studentSelected.id);
                this.setEmployee(this.teacherSelected);
                try {
                    await this.postSubjectStudent();
                    const { data } = await this.getSubjectStudents();
                    this.subjectStudents = data;
                    this.dialogStudent = false;                                   
                } catch (error) {
                    if(error.response) console.log(error.response);
                    else console.log(error);
                    this.snackText = 'Error Occured';                    
                }
                this.dialogStudent = false;
                setTimeout(() => {
                    this.overlay = false;
                    this.snackText = `${this.studentSelected.name} added.`
                    this.snackColor = "primary";
                    this.snackCentered = false;                    
                    this.snackbar = true;
                    
                }, 500);                
                //console.log('setting subject student ...');
            }
            else console.log('information missing');
        },

        async editViewSubjectAssignment (item) {            
            this.editedSubject = item;
            this.loadingAssignedStudents = true; 
            this.dialog = true;
            this.setSubject(item.id);            
            
            try {
                const [
                    { data: dataSubjectStudents },
                    { data: dataStudents }
                ] = await Promise.all([
                    this.getSubjectStudents(),
                    this.getStudents()
                ])
                
                this.subjectStudents = dataSubjectStudents.map(value => {
                    value.name = value.last_name + ' ' + value.first_name;
                    return value;
                });

                this.students = dataStudents.map(value => {
                    return {
                        id: value.id,
                        name: value.first_name + ' ' + value.last_name,
                        form_class: value.class_id
                    }
                })
                             
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }

            this.loadingAssignedStudents = false; 
        },

        addStudent () {
            console.log('adding student...');
            this.teacherSelected = null;
            this.studentSelected = null;
            this.dialogStudent = true;
        },

        displaySelect () {
            this.showSelect = true;
        },

        deleteStudents () {
            console.log('selected', this.selected)
            if(this.selected.length > 0){
                this.setSelectedStudents(this.selected);                
                this.deleteOverlay = true;
                this.progressDelete = false;
                setTimeout(() => {
                    this.displayDeleteConfirm = true;
                }, 700);                
            }
            else{
                this.snackbar = true;
                this.snackColor = "red";
                this.snackCentered = true;
                this.snackText = "No Students selected to delete";
            }
                    
        },

        async deleteConfirm () {
            this.displayDeleteConfirm = false;
            setTimeout(() => {
                this.progressDelete = true;
            }, 700);

            try {
                let response = await this.deleteSubjectStudent();
                console.log(response);
                response = await this.getSubjectStudents();                
                this.$nextTick(() => {
                    this.subjectStudents = response.data;
                })
                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.deleteOverlay = false;
            setTimeout(() => {
                this.snackbar = true;
                this.snackColor = "primary"
                this.snackCentered = false;
                if(this.selected.length == 1) 
                this.snackText = `Student Deleted.`;
                else if(this.selected.length > 1)
                this.snackText = `${this.selected.length} Students Deleted`
            })    
        },

        closeStudentDialog () {
            this.dialogStudent = false;
        },

        closeEdit () {
            this.dialog = false;
            this.subjectStudents = [];
            this.selected = [];
            this.clearUpdateFeedback();
        },

        async updateTeacher (item) {            
            this.setStudent(item.id);
            this.setEmployee(item.employee_id);
            this.updateFeedback('saving');
            try {
                await this.postSubjectStudent();
                // console.log(response);
                this.updateFeedback('saved');
            } catch (error) {
                if(error.response) console.log(error.response);
                console.log(error);
                this.updateFeedback('error');
            }
            
        },

        cancelDeleteStudents () {
            this.showSelect = false;            
            this.selected = [];
        },

        cancelDeleteConfirm () {
            this.displayDeleteConfirm = false;
            setTimeout(() => {
                this.deleteOverlay = false
            }, 600)            
        },
        
        updateFeedback (action = "clear") {
            this.updating = false;
            this.clearUpdateFeedback(); 
            setTimeout(() => {
                this.updating = true;
                this.displayFeedback(action);
            }, 1000);
        },

        displayFeedback (action) {
            if (action == "saving") {
                this.clearUpdateFeedback();
                this.saveStatus = "Saving";
                this.saving = true;
            }
            else if(action == "saved"){
                this.clearUpdateFeedback();
                this.saveStatus = "Update Successfull";
                this.saved = true; 
            }
            else if (action == "error") {
                this.clearUpdateFeedback();
                this.saveStatus = "An Error Occured.";
                this.saveError = true;
            }           
            
        },

        clearUpdateFeedback () {
            this.saveStatus = ''; 
            this.saving = false;
            this.saved = false;
            this.saveError = false;
        }
    }
}
</script>